<template>
  <div class="py-10 py-md-20 px-9 px-md-18">
    <div class="d-flex flex-wrap justify-space-between align-center">
      <div class="d-flex flex-wrap pr-6">
        <v-btn
          class="defaultBg accentColor--text subtitle-1 px-10 mr-6 mr-md-8 mb-6 mb-md-12"
          height="48"
          depressed
          @click="openDialogCreate"
        >
          Add Funds
        </v-btn>
        
        <div class="input-header-container pr-6 pb-6 pb-md-12">
          <DateRangePicker
            v-model="range"
            @input="loadInvoices"
          />
        </div>
        
        <v-btn
          class="defaultBg  mb-6 mb-md-12"
          height="48"
          min-width="58"
          depressed
          @click="loadInvoices"
        >
          <v-icon color="accentColor">mdi-reload</v-icon>
        </v-btn>
      </div>
      
      <div class="d-flex flex-wrap">
        <div class="input-header-container">
          <v-text-field
            v-model="search"
            class="accentColor--text pb-6 pb-md-12"
            background-color="defaultBg"
            label="Search"
            flat
            solo
            hide-details
            :readonly="loading"
            @input="serverSearch($event, 'loadInvoices')"
          >
            <template #prepend-inner>
              <v-icon
                v-if="search.length"
                color="primary"
                @click="clearSearch('loadInvoices')"
              >mdi-close</v-icon>
              
              <v-icon
                v-else
                color="defaultColor"
              >mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </div>
      </div>
    </div>
    
    <v-data-table
      class="defaultBg"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :custom-sort="() => items"
      loader-height="2"
      mobile-breakpoint="0"
      hide-default-footer
      disable-pagination
    >
      <template #item.amount="{ item }">
        {{ currency(item.amount) }}
      </template>
  
      <template #item.amountPaid="{ item }">
        {{ currency(item['amount_paid']) }}
      </template>
  
      <template #item.btcPrice="{ item }">
        {{ item['btc_price'] ? currency(item['btc_price']) : '-//-' }}
      </template>
  
      <template #item.btcPaid="{ item }">
        {{ item['btc_paid'] ? currency(item['btc_paid']) : '-//-' }}
      </template>
      
      <template #item.createdDate="{ item }">
        <span class="text-no-wrap">{{ getFormatDate(item['created_at']) }}</span>
      </template>
      
      <template #item.paymentDate="{ item }">
        <span class="text-no-wrap">
          {{ item['payment_date'] ? getFormatDate(item['payment_date']) : '-//-' }}
        </span>
      </template>
      
      <template #item.createdBy="{ item }">
        <span class="text-no-wrap">{{ item['created_by'] ? item['created_by'].name : '-//-' }}</span>
      </template>
      
      <template #item.payment="{ item }">
        {{ item['payment_system'] ? item['payment_system'].name : '-//-' }}
      </template>
      
      <template #item.actions="{ item }">
        <TooltipAction bottom message="Resend" v-if="item['status_id'] === 2">
          <template #icon>
            <v-icon color="accentColor" @click="openDialogResend(item)">mdi-email-sync</v-icon>
          </template>
        </TooltipAction>
  
        <TooltipAction bottom message="Link to pay" v-if="item['status_id'] === 2">
          <template #icon>
            <v-icon color="accentColor" @click="getInvoiceLink(item.id)">mdi-currency-usd</v-icon>
          </template>
        </TooltipAction>
        
        <TooltipAction bottom message="Delete" v-if="item['status_id'] === 2">
          <template #icon>
            <v-icon color="accentColor" @click="openDialogDelete(item)">mdi-delete</v-icon>
          </template>
        </TooltipAction>
      </template>
    </v-data-table>
    
    <v-pagination
      class="text-center pt-6"
      v-if="items.length && !(dataPagination['last_page'] === 1)"
      v-model="dataPagination['current_page']"
      :length="dataPagination['last_page']"
      :page="dataPagination['current_page']"
      total-visible="8"
      :disabled="loading"
      @input="pageNext($event, 'loadInvoices')"
    />
    
    <DialogInvoice
      v-model="dialogInvoice"
    />
  
    <DialogPaymentLink />
    
    <DialogConfirm
      v-model="dialogConfirm"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      :loading="actionLoading"
      @successAction="confirmAction"
      @cancel="dialogConfirmWasCanceled"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import serverSearch from '@/mixins/serverSearch'
import changeOnPage from '@/mixins/changeOnPage'
import pageNext from '@/mixins/pageNext'
import currency from '@/mixins/currency'

import DateRangePicker from '@/components/pickers/DateRangePicker'
import DialogConfirm from '@/components/dialogs/DialogConfirm'
import DialogInvoice from '@/components/dialogs/DialogInvoice'
import DialogPaymentLink from '@/components/dialogs/DialogPaymentLink'
import TooltipAction from '@/components/tooltip/TooltipAction'

import { format } from 'date-fns'

const isoFormat = 'Y-MM-dd'
const defaultDate = function() {
  return format(new Date(), isoFormat)
}

export default {
  name: 'BuyerInvoices',
  components: { DateRangePicker, DialogConfirm, DialogInvoice, DialogPaymentLink, TooltipAction },
  mixins: [ serverSearch, changeOnPage, pageNext, currency ],
  data: () => ({
    range: [defaultDate(), defaultDate()],
    options: {},
    headers: [
      { text: 'Id', value: 'id', sortId: 'byId' },
      { text: 'Amount', value: 'amount', sortId: 'byAmount' },
      { text: 'Amount Paid', value: 'amountPaid', sortId: 'byAmountPaid' },
      { text: 'BTC', value: 'btcPrice', sortId: 'byBtcPrice' },
      { text: 'BTC Paid', value: 'btcPaid', sortId: 'byBtcPaid' },
      { text: 'Status', value: 'status.name', sortId: 'byStatus' },
      { text: 'Creation Date', value: 'createdDate', sortId: 'byCreationDate' },
      { text: 'Payment Date', value: 'paymentDate', sortId: 'byPaymentDate' },
      { text: 'Pay method', value: 'payment', sortId: 'byPayMethod' },
      { text: 'Created by', value: 'createdBy', sortId: 'byCreatedBy' },
      { text: 'Actions', value: 'actions', align: 'end', width: 150, sortable: false },
    ],
    buyerToFilter: null,
    dialogInvoice: false,
    dialogConfirm: false,
    invoiceIdForResend: null,
    deleteInvoiceId: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    serverSorting: null,
  }),
  computed: {
    ...mapState({
      buyers: state => state.buyers.allItems,
      items: state => state.invoices.items,
      loading: state => state.invoices.loading,
      actionSuccess: state => state.invoices.actionSuccess,
      actionLoading: state => state.invoices.loadingAfterAction,
      dataPagination: state => state.invoices.dataPagination,
    })
  },
  methods: {
    ...mapActions({
      getInvoices: 'invoices/GET_INVOICES',
      getInvoiceLink: 'invoices/GET_INVOICE_LINK',
      resendInvoice: 'invoices/RESEND_INVOICE',
      deleteInvoice: 'invoices/DELETE_INVOICE',
      resetState: 'invoices/RESET_STATE',
    }),
    loadInvoices() {
      const sortBy = this.serverSorting ? { ...this.serverSorting } : {}
      const params = {
        date: this.range[0],
        dateTo: this.range[1],
        ...this.pageParams,
        search: this.search ? this.search : undefined,
        ...sortBy,
        buyer: this.buyerToFilter
      }
      this.getInvoices(params)
    },
    openDialogCreate() {
      this.dialogInvoice = true
    },
    openDialogResend(item) {
      this.invoiceIdForResend = item.id
      this.dialogTitle = 'Resend'
      this.dialogSubTitle = `Invoice #${item.id}`
      this.dialogMessage = 'Are you sure you want to resend this Invoice?'
      this.dialogConfirm = true
    },
    openDialogDelete(item) {
      this.deleteInvoiceId = item.id
      this.dialogTitle = 'Delete'
      this.dialogSubTitle = `Invoice #${item.id}`
      this.dialogMessage = 'Are you sure you want to delete this Invoice?'
      this.dialogConfirm = true
    },
    confirmAction() {
      if (!!this.deleteInvoiceId) {
        this.deleteInvoice(this.deleteInvoiceId)
      } else {
        this.resendInvoice(this.invoiceIdForResend)
      }
    },
    dialogConfirmWasCanceled() {
      this.deleteInvoiceId = null
      this.invoiceIdForResend = null
    },
    getFormatDate(date) {
      return format(new Date(date), 'Y-MM-dd HH:mm:ss')
    }
  },
  watch: {
    options: {
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'desc' : 'asc' }
        } else {
          this.serverSorting = null
        }
        this.loadInvoices()
      }
    },
    actionSuccess: {
      handler(value) {
        if (value) {
          if (this.items.length === 1 && this.deleteInvoiceId && this.pageParams.page !== 1) {
            this.pageParams.page = this.pageParams.page - 1
            this.deleteInvoiceId = null
            this.invoiceIdForResend = null
          }
          this.loadInvoices()
        }
      }
    }
  },
  beforeDestroy() {
    this.resetState()
  }
}
</script>
